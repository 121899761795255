import { useState } from 'react';
import { Block } from 'types/page';
import { ReactComponent as GearIcon } from 'Assets/gear.svg';
import BlockSettings from 'BlockSettings/BlockSettings';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';
import ToolbarManipulatorPopup from '../../Common/ToolbarManipulatorPopup/ToolbarManipulatorPopup';

import styles from './SettingsAction.module.scss';

interface IProps {
  block: Block;
  enabled: boolean;
}

const SettingsAction = ({ block, enabled }: IProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <ToolbarManipulatorButton
      className={styles.settingsButton}
      onClick={() => setIsModalVisible(true)}
      enabled={enabled}
    >
      <GearIcon />
      <ToolbarManipulatorPopup
        className={styles.popup}
        isPopupVisible={isModalVisible}
        onPopupClose={() => setIsModalVisible(false)}
        popupContent={
          <BlockSettings
            block={block}
            onClose={() => setIsModalVisible(false)}
          />
        }
      />
    </ToolbarManipulatorButton>
  );
};

export default SettingsAction;

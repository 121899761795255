import { ReactComponent as SubmenuIcon } from 'Assets/submenu.svg';
import { ActionGroup, ActionType, SubmenuActionType } from '../types';
import BookCoverTemplateAction from './BookCoverTemplateAction/BookCoverTemplateAction';
import DownloadBookCoverAction from '../ManipulatorSubmenuActions/AdditionalActions/DownloadBookCoverAction/DownloadBookCoverAction';
// import URLQuickAccessAction from './AdditionalActions/URLQuickAccessAction/URLQuickAccessAction';
import BookAIImageReroll from './AdditionalActions/BookAIImageReroll/BookAIImageReroll';
import ColorSchemeAction from './AdditionalActions/ColorSchemeAction/ColorSchemeAction';
import DuplicateAction from './DuplicateAction/DuplicateAction';
import AIRerollAction from './AIRerollAction/AIRerollAction';
import SettingsAction from './SettingsAction/SettingsAction';
import AIReloadAction from './AIReloadAction/AIReloadAction';
import AIPromptAction from './AIPromptAction/AIPromptAction';
import AIImageReroll from './AIImageReroll/AIImageReroll';
import RemoveAction from './RemoveAction/RemoveAction';
import OrderAction from './OrderAction/OrderAction';
import MoveAction from './MoveAction/MoveAction';

export const actionGroups: ActionGroup = {
  Button: [
    [
      // { type: ActionType.Settings, additionalAction: URLQuickAccessAction },
      { type: ActionType.Duplicate },
      { type: ActionType.Move },
      // { type: ActionType.Settings },
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [{ type: SubmenuActionType.Remove, text: 'Remove' }],
      },
    ],
  ],
  Text: [
    [
      { type: ActionType.Reroll },
      { type: ActionType.Duplicate },
      { type: ActionType.Move },
      { type: ActionType.AiField, isAdmin: true },
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [{ type: SubmenuActionType.Remove, text: 'Remove' }],
      },
    ],
  ],
  TextCover: [
    [
      { type: ActionType.Duplicate },
      { type: ActionType.Move },
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [{ type: SubmenuActionType.Remove, text: 'Remove' }],
      },
    ],
  ],
  Image: [
    [
      { type: ActionType.Reroll },
      { type: ActionType.Duplicate },
      { type: ActionType.Move },
      { type: ActionType.AiReload, isAdmin: true },
      { type: ActionType.AiField, isAdmin: true },
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [{ type: SubmenuActionType.Remove, text: 'Remove' }],
      },
    ],
  ],
  Video: [
    [
      { type: ActionType.Duplicate },
      { type: ActionType.Move },
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [{ type: SubmenuActionType.Remove, text: 'Remove' }],
      },
    ],
  ],
  Timer: [
    [
      { type: ActionType.Duplicate },
      { type: ActionType.Move },
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [{ type: SubmenuActionType.Remove, text: 'Remove' }],
      },
    ],
  ],
  Form: [
    [
      { type: ActionType.Duplicate },
      { type: ActionType.Move },
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [{ type: SubmenuActionType.Remove, text: 'Remove' }],
      },
    ],
  ],
  Stripe: [
    [
      { type: ActionType.Duplicate },
      { type: ActionType.Move },
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [{ type: SubmenuActionType.Remove, text: 'Remove' }],
      },
    ],
  ],
  Row: [
    [
      { type: ActionType.Duplicate },
      { type: ActionType.Move },
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [{ type: SubmenuActionType.Remove, text: 'Remove' }],
      },
    ],
  ],
  Custom: [
    [
      { type: ActionType.Duplicate },
      { type: ActionType.Move },
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [{ type: SubmenuActionType.Remove, text: 'Remove' }],
      },
    ],
  ],
  Section: [
    [{ type: ActionType.AIImageReroll }],
    [
      { type: ActionType.AiField, isAdmin: true },
      { type: ActionType.AiReload, isAdmin: true },
    ],
    [{ type: ActionType.Order }],
    [{ type: ActionType.Duplicate }, { type: ActionType.Remove }],
  ],
  BookCover: [
    [
      { type: ActionType.AIImageReroll, additionalAction: BookAIImageReroll },
      {
        type: ActionType.AIImageReroll,
        additionalAction: BookCoverTemplateAction,
      },
      { type: ActionType.AIImageReroll, additionalAction: ColorSchemeAction },
    ],
    [
      {
        type: ActionType.Submenu,
        icon: SubmenuIcon,
        children: [
          { type: SubmenuActionType.Upload, text: 'Upload Background Image' },
          {
            type: SubmenuActionType.Download,
            text: 'Download Background Image',
          },
          {
            type: SubmenuActionType.Download,
            additionalAction: DownloadBookCoverAction,
          },
        ],
      },
    ],
  ],
};

export const actionComponents = {
  [ActionType.Move]: MoveAction,
  [ActionType.Order]: OrderAction,
  [ActionType.Remove]: RemoveAction,
  [ActionType.Reroll]: AIRerollAction,
  [ActionType.AiField]: AIPromptAction,
  [ActionType.AiReload]: AIReloadAction,
  [ActionType.Settings]: SettingsAction,
  [ActionType.Duplicate]: DuplicateAction,
  [ActionType.AIImageReroll]: AIImageReroll,
};

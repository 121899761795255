import { useRef, useState } from 'react';
import { Block } from 'types/page';
import { ReactComponent as GlobeIcon } from 'Assets/globe.svg';
import { ReactComponent as ArrowLeft } from 'Assets/arrow-left-back.svg';
import { ReactComponent as ChainIcon } from 'Assets/chain.svg';
import { PrimitiveTypes } from '../types';
import Selection from '../Common/Selection/Selection';

import styles from './NavigationSettings.module.scss';

interface IProps {
  block: Block;
}

const NavigationSettings = ({ block }: IProps) => {
  const [, setState] = useState({});

  const inputRef: React.LegacyRef<HTMLInputElement> = useRef(null);

  const forcedUpdate = () => setState({});

  const handleValueChange = (attributeId: string, value: PrimitiveTypes) => {
    block.onAttributeChanged(attributeId, value);
    block[attributeId].value = value;
    block.view.overlay.update();
    forcedUpdate();
  };

  return (
    <div className={styles.container}>
      <Selection
        options={block.destination.options}
        selectedValue={block.destination.value}
        label={
          <div className={styles.selectionLabel}>
            <GlobeIcon /> Destination:
          </div>
        }
        onOptionSelect={(newValue) =>
          handleValueChange('destination', newValue)
        }
      />
      <Selection
        options={block.targetType.options}
        selectedValue={block.targetType.value}
        label={
          <div className={styles.selectionLabel}>
            <ArrowLeft className={styles.mirror} /> Open As
          </div>
        }
        onOptionSelect={(newValue) => handleValueChange('targetType', newValue)}
      />
      {block.url.visible && (
        <div
          className={styles.urlInputContainer}
          onClick={() => {
            if (inputRef.current) inputRef.current.focus();
          }}
        >
          <ChainIcon />
          https://
          <input
            ref={inputRef}
            value={block.url.value?.replaceAll('https://', '')}
            onChange={(e) => {
              const newValue =
                'https://' + e.target.value.replaceAll('https://', '');
              handleValueChange('url', newValue);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default NavigationSettings;

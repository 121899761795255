import { BlockType } from 'types/page';

export enum ActionType {
  Move,
  Order,
  Reroll,
  Remove,
  Submenu,
  AiField,
  Settings,
  Duplicate,
  AiReload,
  AIImageReroll,
}

export enum SubmenuActionType {
  Remove,
  Download,
  Upload,
  Reroll,
}

export type ActionGroup = Record<BlockType, Array<Array<ManipulatorAction>>>;

export type ManipulatorAction = {
  type: ActionType;
  additionalAction?: React.JSXElementConstructor<any>;
  isAdmin?: boolean;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  children?: Array<ManipulatorSubmenuAction>;
};

export type ManipulatorSubmenuAction = {
  type: SubmenuActionType;
  text?: string;
  additionalAction?: React.JSXElementConstructor<any>;
};

import { ReactComponent as RemoveIcon } from 'Assets/remove.svg';
import { Block } from 'types/page';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';

interface IProps {
  block: Block;
  enabled: boolean;
}

const RemoveAction = ({ block, enabled }: IProps) => {
  const handleClick = () => {
    block.removeFromParent();
    const view = block.view;
    const overlay = view.overlay;
    overlay.clearSelection();
    view.update();
  };

  return (
    <ToolbarManipulatorButton onClick={handleClick} enabled={enabled}>
      <RemoveIcon />
    </ToolbarManipulatorButton>
  );
};

export default RemoveAction;
